<template>
	<div>
		<AppHeaderTitle :Header="Header" />
		<AppHeaderInfo :infos="infos" />
		<b-card ref="formContainer">
			<b-table 
      :fields="fields" 
      :items="Trads.datas" 
      :small="small"
      >
				<!-- Column: key -->
				<template #cell(key)="key">
					<b-badge variant="primary">
						{{ key.item.key }}
					</b-badge>
				</template>
				<!-- Column: precent -->
				<template #cell(t_pct)="data">
					<b-row>
						<b-col md="10">
						<b-progress
							height="15px"
							:value='data.item.t_pct'
							:label="`{{value}}` "
							max="100"
							show-value
							variant="success"
							class="progress-bar-success"
							striped
						/>
						</b-col>
					</b-row>
				</template>
				<!-- Column: Actions -->
				<template #cell(action)="data">
					<b-row>
						<b-col>
							<b-button variant="flat-primary" @click="viewDetails(data.item.key)" size="sm">
								<feather-icon icon="SearchIcon" />
								details
							</b-button>
						</b-col>	
					</b-row>
				</template>
			</b-table>
		</b-card>
	</div>
</template>
<script>
import {
	BTable,
	BCard,
	BRow,
	BButton,
	BProgress,
	BBadge,
	BCol,
} from "bootstrap-vue";
import { codeBasic } from "./code";
import AppHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfo from "@/app/views/components/AppHeaderInfo.vue";
import { Translations } from "@/mixins/i18n.js";

export default {
	components: {
		AppHeaderInfo,
		BTable,
		BCard,
		AppHeaderTitle,
		BRow,
		BButton,
		BProgress,
		BBadge,
		BCol
	},
	mixins: [Translations],
	data() {
		return {
			small: true,
			Trads: [],
			codeBasic,
			fields: [
				{ key: "key", label: "KEY" },
				{ key: "info", label: "INFORMATION" },
				{ key: "t_pct", label: "POURCENTAGE DE TRADUCTION" },
				"ACTION",
			],
			Header: {
        config: "configuration",
				listLink: "",
				list: "traduction",
			},
			infos: {
				title: "Information",
				content: "texte explicatif de la page",
			},
		};
	},
	async mounted() {
		await this.loadTrans();
	},
	methods: {
		viewDetails(key) {
			this.$router.push({ path: `/configuration/i18n/list/${key}` });
		},
		async loadTrans() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
				});
			try {
				const datas = await this.getAll();
				if (datas.status === 1 ) {
					this.Trads = datas
				} else {
					this.$sweetError("AF-43");
				} 
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("AF-42");
			}
		},
	},
};
</script>
